body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media (max-width: 629px) {
    img#auth {
        display: none;
    }
}
.sidebar {
    margin: 0;
    padding: 0;
    width: 200px;
    background-color: #7E9ADF;
    position: fixed;
    height: 100%;
    overflow: auto;
}
.sidebar a {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none;
}
.sidebar a.active {
    background-color: #04AA6D;
    color: white;
}
.sidebar a:hover:not(.active) {
    background-color: #555;
    color: white;
}
div.content {
    margin-left: 200px;
    padding: 1px 16px;
    height: 1000px;
}
@media screen and (max-width: 700px) {
    .sidebar {
        width: 100%;
        height: auto;
        position: relative;
    }
    .sidebar a {
        float: left;
    }
    div.content {
        margin-left: 0;
    }
}
@media screen and (max-width: 400px) {
    .sidebar a {
        text-align: center;
        float: none;
    }
}
